import React, { useState, useContext } from 'react';
import { Form } from './Form';
import { JoinForm } from './useJoinForm';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '~/context/i18n';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { registerUser, RegisterUserRequestBody } from '~/requests/user';
import { logger, ONMFeature } from '~/services/logging';
import { signUp } from '~/services/auth';

interface Props {
  comingFromHealthSubmission?: boolean;
}

export const Join: React.FC<Props> = ({ comingFromHealthSubmission }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { country } = useContext(CountryContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sanitizeInputs = (values: JoinForm): JoinForm => {
    const {
      email,
      nickname,
      notificationPreference,
      phoneNumber,
      password,
      confirmPassword,
    } = values;

    return {
      email: email.trim(),
      nickname: nickname.trim(),
      phoneNumber: phoneNumber ? phoneNumber.replace(/[^+\d]+/g, '') : '', // remove everything except numbers and + sign
      password,
      confirmPassword,
      notificationPreference,
    };
  };

  const createUserRegistrationRequestBody = (
    values: JoinForm
  ): RegisterUserRequestBody => ({
    email: values.email,
    nickname: values.nickname,
    countryCode: country.code.toUpperCase(),
    remindByEmail: /email/i.test(values.notificationPreference),
    remindByPhone: /text/i.test(values.notificationPreference),
    phoneNumber: values.phoneNumber && country.phoneCode + values.phoneNumber,
  });

  const handleExistingUserError = error => {
    const errorCode = error?.response?.data?.errorCode;
    if (errorCode !== 'EmailAlreadyInUse') {
      throw error;
    }
  };

  const onSubmit = async (values: JoinForm) => {
    if (!executeRecaptcha) {
      return showSnackbar(t('ErrorHandling.recaptcha'), 'error');
    }

    try {
      setLoading(true);
      const recaptchaToken = await executeRecaptcha('join');
      const sanitizedValues = sanitizeInputs(values);

      const userRegistrationRequestBody = createUserRegistrationRequestBody(
        sanitizedValues
      );

      await registerUser(userRegistrationRequestBody, recaptchaToken).catch(
        handleExistingUserError
      );

      await signUp(
        sanitizedValues.email,
        sanitizedValues.password,
        sanitizedValues.nickname
      );

      navigate('welcome', {
        replace: true,
        state: { username: sanitizedValues.nickname },
      });
    } catch (error) {
      logger.error(ONMFeature.JOIN, error, { userEmail: values.email });
      const errorCode = error?.response?.data?.errorCode ?? 'Unknown';
      showSnackbar(t(`Join.form.errors.${errorCode}`), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      loading={loading}
      onValidSubmit={onSubmit}
      onCancel={() => navigate('', { replace: true })}
      renderThankYouMessage={comingFromHealthSubmission}
    />
  );
};
