import styled from 'styled-components';

import { Typography } from '@material-ui/core';
import breakpoint from '~/common/breakpoint';

interface Props {
  fullWidth?: boolean;
}
export const Agree = styled(Typography)<Props>`
  color: ${props => props.theme.palette.common.black};
  font-size: ${props => props.theme.typography.caption.fontSize};
  text-align: center;

  ${breakpoint.sm`
    text-align: left;
    max-width: ${props => (!props.fullWidth ? '255px;' : 'none')}
  `}
`;
