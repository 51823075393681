import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { Join } from '~/components/Join';

interface Props extends PageProps {
  location: Location & {
    state: {
      comingFromHealthSubmission?: boolean;
    };
  };
}

const JoinPage: React.FC<Props> = props => {
  const comingFromHealthSubmission =
    props.location?.state?.comingFromHealthSubmission;

  return (
    <>
      <SEO pageContext={props.pageContext} title="Account.join" />
      <Join comingFromHealthSubmission={comingFromHealthSubmission} />
    </>
  );
};

export default JoinPage;
