import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import { Grid, Hidden, CircularProgress } from '@material-ui/core';
import { InternalLink } from '~/components/BaseElements';
import { ROUTES } from '~/config';

interface Props {
  loading: boolean;
  onJoinButtonClick: () => void;
  onCancelButtonClick: () => void;
}

export const FormActions: React.FC<Props> = ({
  loading,
  onJoinButtonClick,
  onCancelButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.FormActionsContainer>
      <Grid item>
        <Styled.SubmitButton
          type="submit"
          disabled={loading}
          onClick={onJoinButtonClick}
          aria-label={t('Join.form.buttons.join')}
          data-testid="submit_button"
          endIcon={
            loading && <CircularProgress data-testid="loading" size={18} />
          }
        >
          {t('Join.form.buttons.join')}
        </Styled.SubmitButton>
      </Grid>
      <Grid item>
        <Styled.CancelButton
          disabled={loading}
          onClick={onCancelButtonClick}
          aria-label={t('Join.form.buttons.cancel')}
          data-testid="cancel_button"
        >
          {t('Join.form.buttons.cancel')}
        </Styled.CancelButton>
      </Grid>
      <Hidden mdUp>
        <Styled.FormActionsFooter>
          <Styled.FormActionsLink
            component={InternalLink}
            to={ROUTES.signin}
            align="center"
            color="primary"
          >
            {t('Join.form.alreadyJoinedCTA')}
          </Styled.FormActionsLink>
        </Styled.FormActionsFooter>
      </Hidden>
    </Styled.FormActionsContainer>
  );
};
