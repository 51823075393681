import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExternalLink } from '~/components/BaseElements';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';
import { ROUTES } from '~/config';

import * as Styled from './styles';

interface Props {
  fullWidth?: boolean;
}

export const AgreeTermsAndConditions: React.FC<Props> = ({ fullWidth }) => {
  const languageUrl = useLanguageUrl();
  const { t } = useTranslation();

  return (
    <Styled.Agree fullWidth={fullWidth}>
      <Trans i18nKey="TermsAndCondition.agree">
        <ExternalLink to={`${languageUrl}${ROUTES.terms}`}>
          {t('Links.termsAndConditions')}
        </ExternalLink>
        <ExternalLink to={`${languageUrl}${ROUTES.privacyPolicy}`}>
          {t('Links.privacyPolicy')}
        </ExternalLink>
      </Trans>
    </Styled.Agree>
  );
};
