import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { InternalLink } from '~/components/BaseElements';
import { ROUTES } from '~/config';
import * as Styled from './styles';
import * as ThankYouStyles from '~/components/ThankYou/styles';

interface Props {
  renderThankYouMessage: boolean;
}

export const FormHeader: React.FC<Props> = ({ renderThankYouMessage }) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={1}>
      {renderThankYouMessage && (
        <Grid item xs>
          <ThankYouStyles.HeadingMessage>
            <Trans i18nKey="Join.form.thankYouHeader"></Trans>
          </ThankYouStyles.HeadingMessage>
          <ThankYouStyles.BodyMessage>
            {t('Join.form.thankYouMessage')}
          </ThankYouStyles.BodyMessage>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Typography
            variant={renderThankYouMessage ? 'h5' : 'h4'}
            align="left"
          >
            {t('Join.form.headerTitle')}
          </Typography>
          <Hidden smDown>
            <Styled.FormActionsLink
              component={InternalLink}
              to={ROUTES.signin}
              align="right"
              color="primary"
              style={{ textDecoration: 'underline' }}
            >
              {t('Join.form.alreadyJoinedCTA')}
            </Styled.FormActionsLink>
          </Hidden>
        </Grid>
      </Grid>
      <Styled.FnyMessageContainer>
        <Typography align="left" variant="body1">
          {t('Join.form.fnyMessage')}
        </Typography>
      </Styled.FnyMessageContainer>
      <Typography paragraph variant="caption" align="left">
        {t('Join.form.legend')}
      </Typography>
    </Grid>
  );
};
