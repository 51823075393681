/* eslint-disable camelcase */
import { emailPattern, digitsOnly } from '~/common/regex';
import { useTranslation } from 'react-i18next';
import { validateFirstName } from '~/common/validations';
import { RegisterOptions, useForm, UseFormMethods } from 'react-hook-form';

export interface JoinForm {
  email: string;
  nickname: string;
  password: string;
  confirmPassword: string;
  notificationPreference: string;
  phoneNumber: string;
}

interface UseJoinForm {
  form: UseFormMethods<JoinForm>;
  validationSchema: Map<keyof JoinForm, RegisterOptions>;
}

export const useJoinForm = (): UseJoinForm => {
  const { t } = useTranslation();

  const defaultNotificationSetting = t(
    'Join.form.notifications.options.0.value'
  );

  const form = useForm<JoinForm>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      nickname: '',
      notificationPreference: defaultNotificationSetting,
      phoneNumber: '',
      password: '',
      confirmPassword: '',
    },
  });

  const validationSchema = new Map<keyof JoinForm, RegisterOptions>();
  validationSchema.set('nickname', {
    required: true,
    validate: validateFirstName,
  });
  validationSchema.set('email', { required: true, pattern: emailPattern });
  validationSchema.set('notificationPreference', { required: true });
  validationSchema.set('phoneNumber', {
    required: /text/i.test(form.getValues().notificationPreference),
    pattern: digitsOnly,
  });
  validationSchema.set('password', {
    required: true,
    minLength: 8,
    validate: {
      number: value => /[\d]{1}/.test(value),
      letter: value => /[a-zA-Z]/.test(value),
    },
  });
  validationSchema.set('confirmPassword', {
    required: true,
    validate: {
      match: value => value === form.getValues('password'),
    },
  });
  return { form, validationSchema };
};
